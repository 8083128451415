// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import GraphImg from 'graphcms-image';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '1.875rem'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Container = styled.div`
  padding: 6.25rem 0;
  display: grid;
  grid-column: 2 / -2;

  @media ${device.laptop} {
    width: 100%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;

  @media ${device.laptop} {
    width: 100%;
    height: 12.5rem;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  @media ${device.mobile} {
    width: 100%;
    height: 12.5rem;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const BoxImage = styled.div`
  width: 29.375rem;
  height: 29.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    width: 12.5rem;
    height: 12.5rem;
    position: relative;
    right: -48px;
  }

  @media ${device.mobile} {
    right: -45px;
  }

  > div {
    width: 100%;

    > div {
      width: 29.375rem;
      height: 29.375rem;

      @media ${device.laptop} {
        width: 12.5rem;
        height: 12.5rem;
      }

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const BoxTexts = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 8.125rem;

  @media ${device.laptop} {
    width: auto;
    padding-left: 0;
  }

  @media ${device.mobile} {
    padding: 0;
    position: relative;
    left: 30px;
    margin-left: -2rem;
  }

  > p {
    width: 100%;

    :first-child {
      margin-bottom: 0;
      font-size: 1.5rem;
      padding-bottom: 4.375rem;

      @media ${device.laptop} {
        padding-bottom: 1.875rem;
        font-size: 1.125rem;
      }

      @media ${device.mobile320} {
        padding-bottom: 1rem;
      }
    }

    :last-child {
      display: flex;
      line-height: 1.625rem;
      text-align: left;

      @media ${device.laptop} {
        display: none;
      }

      > a {
        line-height: 1.625rem;
        color: #45a7df;
        margin: 0 0.3125rem;

        :hover {
          text-decoration: underline;
        }

        :last-child {
          margin-right: 0;
        }

        @media ${device.mobile} {
          font-size: 0.875rem;
        }

        @media ${device.mobile375} {
          font-size: 0.8125rem;
        }

        @media ${device.mobile320} {
          font-size: 0.6875rem;
        }
      }
    }
    > em {
      line-height: 1.875rem;
      font-weight: 400;
      font-style: normal;
      color: #8f9194;

      @media ${device.laptop} {
        font-size: 1.125rem;
        padding-bottom: 0;
      }

      @media ${device.mobile375} {
        font-size: 1rem;
      }
    }
  }

  > h1 {
    width: 29.375rem;
    font-weight: 700;
    line-height: 3.75rem;
    padding-bottom: 3.125rem;
    font-size: 3.375rem;

    @media ${device.laptop} {
      width: 100%;
      font-size: 1.5rem;
      line-height: 30px;
      padding-bottom: 0;
    }

    @media ${device.mobile} {
      width: 100%;
      font-size: 1.45rem;
      line-height: 30px;
    }

    @media ${device.mobile375} {
      font-size: 1.1rem;
    }

    @media ${device.mobile320} {
      font-size: 0.82rem;
    }

    @media ${device.mobile280} {
      font-size: 1rem;
    }
  }
`;

const TextMobile = styled.p`
  display: none;
  line-height: 1.625rem;
  font-size: 0.875rem;

  @media ${device.laptop} {
    display: flex;
    padding-top: 1.25rem;
  }

  @media ${device.mobile320} {
    font-size: 0.6875rem;
  }

  @media ${device.mobile280} {
    font-size: 0.6rem;
  }
`;

const LinkAtPage = styled(Link)`
  line-height: 1.625rem;
  margin: 0 5px;
  color: #45a7df;

  :hover {
    text-decoration: underline;
  }

  :last-child {
    margin-right: 0;
  }

  @media ${device.mobile320} {
    font-size: 0.6875rem;
  }

  @media ${device.mobile280} {
    font-size: 0.6rem;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => (
  <GridContainer isDesktop={isDesktop}>
    <Container>
      <Content>
        <BoxImage>
          <GraphImg
            image={getMarkdown(page, 'ckoj4zyww1u2v0b76u2wcbt9j', false, true)}
            className="Image"
            alt="Error 404"
            fadeIn={true}
            withWebp={true}
          />
        </BoxImage>
        <BoxTexts>
          <ReactMarkdown
            children={getMarkdown(page, 'ckoj4zyww1u2v0b76u2wcbt9j', true)}
          />
        </BoxTexts>
      </Content>
      <TextMobile>
        Você pode <LinkAtPage to="/">voltar para home</LinkAtPage> ou{' '}
        <LinkAtPage to="/fale-conosco/">falar conosco</LinkAtPage>.
      </TextMobile>
    </Container>
  </GridContainer>
);

const NotFoundPage = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckoj4vqwo1tg80b76weq0rqz9" }) {
                fragments(where: { id: "ckoj4zyww1u2v0b76u2wcbt9j" }) {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <NotFoundPage page={response.gcms.site.pages} />;
      }}
    />
  );
};
